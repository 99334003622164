.table_td {
  line-height: 110%;
  overflow-wrap: "break-all";
}

.table_head {
  color: #fff;
  background-color: #2cba8d;
  top: 0%;
  position: sticky;
}

.even_row {
  background-color: #d6f5d6;
}
.even_row:hover {
  background-color: #dfeaf6;
  color: black;
}

.odd_row {
  background-color: white;
}
.odd_row:hover {
  background-color: #dfeaf6;
  color: black;
}

.main_row {
  background-color: #eee8aa;
  font-weight: 600;
}

.table_number_cell {
  text-align: right;
}

.table_actions {
  text-align: center;
}

.table_actions_spread {
  justify-content: space-around;
}

.table_text {
  text-align: left;
}
